"use client";
import React from "react";
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import {Shield, WalletIcon, WorkflowIcon, HandCoinsIcon, ExpandIcon} from "lucide-react";
import 'react-vertical-timeline-component/style.min.css';

const Timeline: React.FC = () => {
    const timelines = [
        {
            name: "Phase 1: Foundation – Trust & Security (In Development)",
            text: [
                "Fraud Detection Engine",
                "Smart Contract Intelligence",
                "Wallet Overview Dashboard",
            ],
            date: "November 2024 - present",
            icon: (<Shield/>),
            bgColor: "#3b82f6",
        },
        {
            name: "Phase 2: Monchain Wallet",
            text: [
                "Non-Custodial Mobile Wallet",
                "Built-in AI Security Layer",
                "Staking & Rewards System",
            ],
            date: "June 2025",
            icon: (<WalletIcon/>),
            bgColor: "#F25A71",
        },
        {
            name: "Phase 3: The Smarter Exchange",
            text: [
                "Launch of Monchain Exchange",
                "Trust-Rated Listings",
                "On-Chain Insights & Trader Analytics",
            ],
            date: "December 2025",
            icon: (<HandCoinsIcon/>),
            bgColor: "#F25A71",
        },
        {
            name: "Phase 4: Expansion & Ecosystem",
            text: [
                "On-Chain Reputation Protocol (R&D)",
                "Monchain Developer Platform",
                "Institutional & DeFi Partnerships",
                "Governance & DAO Evolution",
            ],
            date: "January 2026",
            icon: (<ExpandIcon/>),
            bgColor: "#F25A71",
        },
    ];

    return (
        <section className="bg-white py-8 sm:py-12 lg:py-16">
            <div className="max-w-[1200px] mx-auto px-4 sm:px-5 text-center">
                <h2 className="text-2xl sm:text-3xl font-bold mb-3 sm:mb-4">
                    Roadmap
                </h2>
                <p className="text-sm sm:text-base text-gray-600 mb-6 sm:mb-8 max-w-[600px] mx-auto">
                    From fraud detection to a trusted crypto exchange — this is just the beginning.
                </p>

                <div className="px-0 sm:px-2">
                    <VerticalTimeline lineColor="#ddd">
                        {timelines.map((item, index) => (
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                dateClassName="text-left text-black"
                                contentStyle={{background: item.date.includes('present') ? item.bgColor : '#ddd', color: item.date.includes('present') ? '#fff' : '#000'}}
                                contentArrowStyle={{borderRight: `7px solid ${item.date.includes('present') ? item.bgColor : '#ddd'}`}}
                                date={item.date}
                                iconStyle={{background: item.date.includes('present') ? item.bgColor : '#ddd', color: item.date.includes('present') ? '#fff' : '#000'}}
                                icon={item.icon}
                                key={index}
                            >
                                <h3 className="text-left font-bold mb-1">{item.name}</h3>
                                <ul>
                                    {item.text.map((text, index) => (
                                        <li key={index} className="text-left">
                                            <WorkflowIcon size={16} className="inline-block mr-2"/>
                                            {text}
                                        </li>
                                    ))}
                                </ul>
                            </VerticalTimelineElement>
                        ))}
                    </VerticalTimeline>
                </div>
            </div>
        </section>
    );
};

export default Timeline;