// Home.tsx
"use client";
import Image from "next/image"; // Import Next.js Image component
import AppPromoSection from "@/components/AppPromoSection/AppPromoSection";
import FeatureSection from "@/components/Features/Feature";
import Hero from "@/components/Hero";
import SecuritySection from "@/components/SecuritySection/SecuritySection";
// import Testimonials from "@/components/Testimonials/Testimonials";
import Timeline from "@/components/Timeline/Timeline";


// Background Image Component
const BackgroundImage = () => (
  <div className="fixed inset-0 w-full h-full -z-10">
    <Image
      src="/bg-11.png"
      alt="Background"
      fill
      priority
      sizes="100vw"
      quality={75}
      className="object-cover sm:object-contain lg:object-cover"
      style={{ objectPosition: "top center" }}
    />
    {/* Optional overlay */}
    <div className="absolute inset-0 bg-white/50 sm:bg-transparent"></div>
  </div>
);

export default function Home() {

  return (
    <main className="relative min-h-screen w-full overflow-x-hidden">
      <BackgroundImage />

      {/* Content Container */}
      <div className="relative z-10 w-full mx-auto">
        <section className="w-full">
          <Hero />
        </section>

        <section className="w-full mt-8 sm:mt-12 lg:mt-16">
          <AppPromoSection />
        </section>

        <section className="w-full mt-8 sm:mt-12 lg:mt-16">
          <FeatureSection />
        </section>

        <section className="w-full mt-8 sm:mt-12 lg:mt-16">
          <SecuritySection />
        </section>

        <section className="w-full mt-8 sm:mt-12 lg:mt-16 mb-8 sm:mb-12 lg:mb-16">
          {/*<Testimonials />*/}
          <Timeline />
        </section>
      </div>
    </main>
  );
}
