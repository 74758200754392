"use client";
import React, {useState, useEffect, useMemo, useRef} from "react";
import Image from "next/image";
import {useRouter} from "next/navigation";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState, store} from "@/store/store";
import {
    initiateFraudEvaluation,
    fetchFraudEvaluationById,
} from "@/store/fraudEvaluationSlice";
import {useToast} from "@/components/Toast/Toast";
import makeBlockie from "ethereum-blockies-base64";
import {MODAL_TYPES} from "@/constants/modalTypes";
import {openModal} from "@/store/modalSlice";

// Types
interface WalletData {
    target: string;
    date_updated: string | null;
}

const Hero: React.FC = () => {
    // State
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [localWallets, setLocalWallets] = useState<WalletData[]>([]);
    const dropdownRef = useRef<HTMLDivElement>(null);

    // Hooks
    const router = useRouter();
    const dispatch = useDispatch<AppDispatch>();
    const {addToast} = useToast();
    const {isAuthenticated} = useSelector(
        (state: RootState) => state.authSlice
    );
    const {initiationLoading} = useSelector((state: RootState) => state.fraudEvaluation);

    // Load wallets from localStorage
    useEffect(() => {
        try {
            const storedWallets = localStorage.getItem("fraudEvaluationTargets");
            setLocalWallets(storedWallets ? JSON.parse(storedWallets) : []);
        } catch (error) {
            console.error("Error loading wallets:", error);
        }
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    // Filtered wallets based on search
    const filteredWallets = useMemo(() => {
        if (!searchValue.trim()) return localWallets;
        const searchLower = searchValue?.toLowerCase();
        return localWallets.filter((wallet) =>
            wallet?.target?.toLowerCase().includes(searchLower)
        );
    }, [localWallets, searchValue]);

    // Event Handlers
    const handleSearch = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!searchValue.trim()) return;

        setIsDropdownOpen(false);

        try {
            if (!isAuthenticated) {
                addToast(
                    "Error",
                    "Please Connect Wallet to Continue Wallet Evaluation"
                );
                return;
            }

            const resultAction = await dispatch(
                initiateFraudEvaluation(searchValue.trim())
            );
            if (initiateFraudEvaluation.fulfilled.match(resultAction)) {
                // Fetch the evaluation data before navigation
                await dispatch(
                    fetchFraudEvaluationById(resultAction.payload.request_id)
                );
                router.push(`/wallet-evaluation/${resultAction.payload.request_id}`);
                addToast(
                    "Success",
                    "Fraud evaluation initiated successfully",
                    "success"
                );
            } else {
                addToast("Error", "Failed to initiate fraud evaluation", "error");
            }
        } catch (error) {
            addToast("Error", "An unexpected error occurred" + error, "error");
        }
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        setIsDropdownOpen(true);
    };

    const handleWalletClick = (target: string) => {
        setSearchValue(target);
        setIsDropdownOpen(false);

        // Follow the same flow as handleSearch
        if (!isAuthenticated) {
            addToast(
                "Error",
                "Please Connect Wallet to Continue Wallet Evaluation"
            );
            return;
        }

        // Initiate fraud evaluation
        dispatch(initiateFraudEvaluation(target))
            .then(async (resultAction) => {
                if (initiateFraudEvaluation.fulfilled.match(resultAction)) {
                    // Fetch the evaluation data before navigation
                    await dispatch(
                        fetchFraudEvaluationById(resultAction.payload.request_id)
                    );
                    router.push(`/wallet-evaluation/${resultAction.payload.request_id}`);
                    addToast(
                        "Success",
                        "Fraud evaluation initiated successfully",
                        "success"
                    );
                } else {
                    addToast("Error", "Failed to initiate fraud evaluation", "error");
                }
            })
            .catch((error) => {
                addToast("Error", "An unexpected error occurred" + error, "error");
            });
    };

    return (
        <section
            className={`relative sm:min-h-screen overflow-hidden ${
                isDropdownOpen ? "mb-20 sm:mb-0" : ""
            }`}
        >
            {/* Wave Pattern Background */}
            <div
                className="hidden sm:block absolute top-[60%] sm:top-[50%] lg:top-[40%] w-full h-[100%] overflow-hidden">
                <Image
                    src="/images/wave-pattern.png"
                    alt="Wave Pattern"
                    fill
                    className="object-cover"
                />
            </div>

            {/* Content */}
            <div
                className="relative z-10 max-w-[1200px] mx-auto px-4 sm:px-5 py-8 sm:py-8 lg:py-8 flex flex-col items-center text-center">
                <h1 className="text-[32px] sm:text-[44px] lg:text-7xl font-albayan font-semibold leading-tight text-black tracking-[-0.02em]">
                    AI-Powered Security
                    <span className="block text-[24px] sm:text-[32px] lg:text-6xl mt-2">
            <span className="font-light">for Your</span> Crypto Assets
          </span>
                </h1>

                <p className="text-sm sm:text-base leading-relaxed text-gray-600 mt-4 sm:mt-2 mb-6 max-w-[480px] px-4 sm:px-0">
                    AI-powered fraud detection, trusted wallet tools, and a smarter exchange — coming soon.
                </p>

                {/* Search Container */}
                <div className="relative w-full max-w-[580px] px-4 sm:px-6 lg:px-0">
                    <form onSubmit={handleSearch} className="relative">
                        <Image
                            src="/images/search-icon.png"
                            alt="Search"
                            width={20}
                            height={20}
                            className="absolute left-6 sm:left-8 lg:left-4 top-1/2 -translate-y-1/2 opacity-40"
                        />

                        <input
                            type="text"
                            value={searchValue}
                            onChange={handleSearchChange}
                            onFocus={() => setIsDropdownOpen(true)}
                            placeholder="Enter a wallet address or transaction hash to evaluate for fraud..."
                            className={`w-full h-[40px] sm:h-[44px] pl-[50px] pr-[60px] ${
                                isDropdownOpen ? "rounded-t-xl" : "rounded-xl"
                            } border border-gray-200 bg-white text-sm sm:text-base text-gray-900 placeholder-gray-400 transition-all duration-200 ease-in-out focus:outline-none focus:border-blue-500 focus:shadow-[0_2px_8px_rgba(59,130,246,0.1)]`}
                        />

                        <button
                            type="submit"
                            disabled={initiationLoading}
                            className="absolute right-6 sm:right-8 lg:right-2 top-1/2 -translate-y-1/2 w-10 h-10 rounded-full flex items-center justify-center transition-colors duration-200 hover:bg-gray-50"
                        >
                            {initiationLoading ? (
                                <div
                                    className="animate-spin rounded-full h-5 w-5 border-2 border-primary border-t-transparent"/>
                            ) : (
                                <Image
                                    src="/images/submit-icon.png"
                                    alt="Submit"
                                    width={20}
                                    height={20}
                                />
                            )}
                        </button>
                    </form>

                    {/* Dropdown */}
                    {isDropdownOpen && (
                        <div
                            ref={dropdownRef}
                            className={`
              bg-white border border-gray-200 rounded-b-lg shadow-lg
              relative sm:absolute w-full
              sm:top-full sm:left-0 sm:right-0
              sm:z-50
            `}
                        >
                            <div className="p-4">
                                <button
                                    type="button"
                                    onClick={() => setIsDropdownOpen(false)}
                                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 p-2"
                                >
                                    ✕
                                </button>
                                <h4 className="text-gray-500 text-start text-xs mb-2">
                                    ADDRESSES
                                </h4>
                                <ul className="max-h-[60vh] overflow-y-auto">
                                    {filteredWallets.length === 0 ? (
                                        <li className="py-2 text-center text-gray-500">
                                            No matching addresses found
                                        </li>
                                    ) : (
                                        filteredWallets.map((wallet) => (
                                            <li
                                                key={wallet.target}
                                                className="flex items-center py-2 cursor-pointer hover:bg-gray-50 px-2 rounded-lg"
                                                onClick={() => handleWalletClick(wallet.target)}
                                            >
                                                <img
                                                    src={makeBlockie(wallet.target)}
                                                    alt={`Avatar for ${wallet.target}`}
                                                    className="w-8 h-8 rounded-full mr-3"
                                                />
                                                <div className="flex-1 min-w-0">
                                                    <div className="flex items-center gap-2 flex-wrap">
                                                        <p className="text-sm font-bold truncate">
                                                            {wallet.target}
                                                        </p>
                                                    </div>
                                                    <p className="text-xs text-start text-gray-500">
                                                        Last sync: {wallet.date_updated || "Never"}
                                                    </p>
                                                </div>
                                            </li>
                                        ))
                                    )}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Hero;
