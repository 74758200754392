import React from "react";
import Image from "next/image";

const AppPromoSection: React.FC = () => {
  return (
    <section className="py-8 sm:py-12 lg:py-16 lg-mt-8">
      <div className="max-w-[1200px] mx-auto px-4 sm:px-5">
        <div className="flex flex-col lg:flex-row items-center gap-8 lg:gap-12">
          {/* Image Container */}
          <div className="w-full lg:w-1/2 flex justify-center lg:justify-start">
            <div className="relative w-[280px] sm:w-[400px] lg:w-[500px]">
              <Image
                src="/images/phone-mockup.png"
                alt="Phone Mockup"
                width={500}
                height={500}
                className="rounded-lg object-cover w-full h-auto"
                priority
              />
              <div className="absolute inset-0 right-0 sm:right-0 bottom-8 flex items-center justify-center">
                <span className="text-black font-bold text-sm sm:text-xl">
                  Monchain
                </span>
              </div>
            </div>
          </div>

          {/* Text Content */}
          <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-start text-center lg:text-left">
            <h4 className="text-blue-500 uppercase text-sm sm:text-base mb-2">
              Monchain Wallet
            </h4>
            
            <h2 className="text-2xl sm:text-3xl font-bold mb-4">
              A Wallet Built for Protection
            </h2>
            
            <p className="text-sm sm:text-base text-gray-600 mb-6 max-w-[600px] lg:max-w-none">
              Your crypto wallet should do more than just hold tokens.
              The upcoming Monchain Wallet comes with built-in AI fraud detection, real-time alerts, and smart contract safety checks—so you always stay one step ahead.
              <br />
              Your crypto is valuable. Let’s keep it that way.
              <br />
            </p>

            <a 
              href="#" 
              className="text-blue-500 font-semibold text-sm sm:text-base hover:text-blue-600 transition-colors duration-200"
            >
              Learn More
            </a>

            {/* App Store Button */}
            <div className="mt-6 w-full sm:w-auto">
              <a 
                href="#" 
                className="block w-[250px] sm:w-[250px] lg:w-[350px] mx-auto lg:mx-0"
              >
                <Image
                  src="/images/app-store.png"
                  alt="App Store"
                  width={350}
                  height={100}
                  className="w-full h-auto"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppPromoSection;